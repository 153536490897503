import React from "react";
import "./ImgFluid.css";

const ImgFluid = () => {
  return (
    <>
      <div className="image ">

      </div>
    </>
  );
};

export default ImgFluid;
